.popup {
    font-family: 'Montserrat';
    display: inline-block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-width: 600px;
    background: #fff;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
    z-index: 10001;
    max-height: 95vh;
    overflow-x: hidden;
    overflow-y: auto;
}

    .popup.noscrollbar::-webkit-scrollbar {
        display: none;
    }

    .popup.noscrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }



.tabs .popup {
    margin-top: 40px;
}

.popup .topSection {
    display: flex;
    flex-flow: nowrap;
    align-content: center;
    width: 100%;
    min-height: 50px;
    color: #fff;
    background: #002B44;
    gap: 20px;
}

    .popup .topSection h4 {
        color: #fff;
        text-align: center;
    }

    .popup .topSection .left {
        padding: 20px 0px 0px 20px;
        flex-grow: 1;
    }

    .popup .topSection .center {
        flex-grow: 4;
    }

    .popup .topSection .right {
        padding: 20px 20px 0px 0px;
        flex-grow: 1;
        text-align: right;
    }

.popup .contentSection {
    display: block;
    white-space: normal;
    padding: 10px;
}

    .popup .contentSection .code {
        font-size: 14px;
        font-family: 'Courier New';
        line-height: 20px;
    }


    .popup .footerSection {
        display: flex;
    }

.popup .btn.btn-modal {
    border-radius: unset;
    margin: 0px;
    border: none;
    width: 50%;
}

.popup .btn.btn-modal-block {
    border-radius: unset;
    margin: 0px;
    border: none;
    width: 100%;
}


/*************************************************************/
/* MEDIA QUIERIES                                            */
/*************************************************************/
@media (max-width: 1024px) {
    .popup {
        width: 95%;
    }
}

@media (max-width: 640px) {
}

@media (max-width: 320px) {
}
