.order textarea {
    resize: none;
}

.order .row .btn {
    margin: 0px;
}

.order .row .control,
.order .row .btn {
    margin: 2px 10px 2px 0px;
}

.order .btn-sm {
    min-width: unset;
    min-height: 20px;
    margin: 0px;
}

.order .btn.btn-square {
    width: 100px;
    height: 100px;
    padding: 0px;
    min-height: unset;
    min-width: unset;
    margin: 0px 20px 20px 0px;
}

.order .modal .button {
    background: var(--color-blue);
    color: var(--color-white);
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 20px 0px 20px 0px;
    cursor: pointer;
    float: left;
    border: none;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.order .modal .button.left {
    background: var(--color-orange);
    width: 50%;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: 20px;
}

.order .modal .button.right {
    background: var(--color-green);
    width: 50%;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: 20px;
}

.order .modal .button:disabled {
    background: var(--color-light-grey);
    color: var(--color-grey);
    border: none;
}

.order .text-align-left {
    text-align: left;
}

.order .text-align-right {
    text-align: right;
}

.order .text-align-center {
    text-align: center;
}

.order .clickable {
    cursor: pointer;
}

.order .notClickable {
    cursor: not-allowed;
}

.order a {
    cursor: pointer;
}

.order .codeblock .toolbar {
    width: 100%;
    padding: 5px;
    display: contents;
    cursor: pointer;
}

.order .codeblock pre {
    font-size: 12px;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.order .blur {
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

.order .loader-screen {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    z-index: 99999999;
}

.order .backSplash {
    opacity: 0.4;
    cursor: default;
    z-index: 10001;
}

.order .backSplash.clear {
    opacity: 0;
}

.order .toggleContainer .toggle {
    margin: 0px;
}

.order .contact-pop-over .pop-over {
    padding: 0px 0px 10px 0px;
    font-size: 16px;
}

.order .contact-pop-over .menu-item {
    padding: 10px 0px 10px 10px;
    cursor: pointer;
}

.order .contact-pop-over .menu-item:hover {
    background: var(--color-dark-blue);
    color: var(--color-white);
}

.order .contact-pop-over .menu-separator {
    border: 1px solid var(--color-light-grey);
}

.order .modal {
    max-width: unset;
    width: 80%;
    overflow: visible;
    padding: 10px;
}

.order .modal .content {
    padding: 10px 10px 0px 10px;
    margin: 0px 0px 10px 0px;
    overflow-y: auto;
    max-height: 75vh;
}

.order .modal .header {
    padding: 0px 10px 0px 10px;
    box-shadow: unset;
    height: unset;
    min-height: 20px;
    display: inline-block;
}

.order .modal .footer {
    padding: 0px;
}

.order .modal .content label {
    color: var(--color-orange);
    font-weight: 800;
}

.order .modal .content .row {
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid var(--color-light-grey);
}

.order .modal .header .btn-close {
    position: absolute;
    right: 30px;
    top: 15px;
    color: var(--color-orange);
    cursor: pointer;
}

.order .article .article-content .image-wrapper {
    margin: 30px 0px 30px 0px;
}

.order .article .article-content img {
    width: -webkit-fill-available;
}

.order .notificationContainer {
    right: 0px;
    top: 110px;
    position: fixed;
    z-index: 10001;
}

.order .notificationContainer .notification {
    width: 250px;
    min-height: 50px;
    margin: 0px 10px 10px 0px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.5);
}

.order .notificationContainer .notification .title {
    font-size: 14px;
    font-weight: bolder;
    padding: 5px 0px 5px 5px;
    margin: 0px;
    display: inline-block;
    overflow: hidden;
}

.order .notificationContainer .notification .title .txt {
    text-align: left;
    float: left;
    display: block;
    width: 214px;
}

.order .notificationContainer .notification .title .btn-close {
    text-align: right;
    float: right;
    display: block;
    cursor: pointer;
}

.order .notificationContainer .notification .content {
    font-size: 12px;
    padding: 0px 5px 5px 5px;
    margin: 0px;
}

.order .notificationContainer .notification.info {
    color: #fff;
    background: var(--color-green);
}

.order .notificationContainer .notification.warn {
    color: #fff;
    background: var(--color-orange);
}

.order .notificationContainer .notification.error {
    color: #fff;
    background: var(--color-error);
}

.order .list.provider .datarow {
    border-bottom: none;
}

.order .list.method .datarow {
    border-bottom: 1px solid var(--color-light-grey);
}

.order .provideredit .row {
    padding: 0px 0px 20px 0px;
}

.order .provideredit .metadata .row {
}

.order .provideredit .metadata .list .row {
    border-top: 1px solid var(--color-light-grey);
}

.order .provideredit .metadata .list .row .btn {
    margin: 10px 10px 10px 0px;
    padding: 0px;
    color: red;
    min-width: 6em;
}

.order .popover .journal {
    width: 150px;
}

.order .popover .journal .row {
    padding: unset;
    border: unset;
}

.order .transactionView .row {
    padding: 8px;
    border-bottom: 1px solid var(--color-light-grey);
}

.order .transactionView .content {
    color: var(--color-orange);
}
