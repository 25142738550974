.flex {
    display: flex;
}

.popup {
    max-width: unset;
}

.popup .contentSection table thead,
.popup .contentSection table tbody {
    font-size: 12px;
}

.popup .contentSection th {
    background: #000;
    color: #fff;
}

.popup .contentSection th,
.popup .contentSection td {
    text-align: left;
    padding: 5px 20px 5px 20px;
    border-bottom: 1px solid #eee;
}


.popup.artwork,
.popup.thumbnail,
.popup.printFile {
    width: unset;
}


    .popup label {
        display: inline-block;
        margin: 20px 0px 5px 0px;
        color: var(--color-blue);
        font-weight: bold;
    }

    .popup .footerSection {
        padding: 40px 0px 0px 0px;
    }



    .audit table td {
        text-align: left;
    }

.audit pre {
    overflow: auto;
}


.cell.small {
    width: 30px;
}


.contentSection .property td {
    width: 25%;
    vertical-align: top;
}

.contentSection .dependentProperty td {
    width: 50%;
}

.contentSection .dependentProperty th {
    background: unset;
    color: #000;
    font-weight: 800;
}



.jmfMessageDetail label {
    width: 100%;
    display: inline-block;
    margin: 20px 0px 5px 0px;
    color: var(--color-blue);
    font-weight: bold;
}

.jmfMessageDetail pre {
    overflow: auto;
    white-space: pre-wrap;
}

