.controlLoader {
    position: absolute;
    width: 300px;
    height: 40px;
    margin: 1px;
    z-index: 1000;
}

.list {
}

    .list .table {
        margin-left: auto;
        margin-right: auto;
        display: table;
        table-layout: fixed;
        width: 100%;
    }


        .list .table .row {
            display: table-row;
        }

            .list .table .row.selectable:hover {
                background: #eeeeee;
            }

            .list .table .row.caption {
                color: #fff;
                background: var(--color-blue);
                font-weight: 600;
            }

            .list .table .row .cell {
                display: table-cell;
                padding: 10px 5px 10px 5px;
                border-bottom: 1px solid #aaa;
                word-break: break-word;
                vertical-align: middle;
            }

            .btn-block {
                width: 100%;
                display: block;
            }


.imageUpload.buttonTile .content img {
    width: 100%;
}

.imageUpload.buttonTile .footer .btns {
    display: flex;
}



/*CHECKBOX*/
.checkboxContainer {
    margin: 0px 0px 12px 0px;
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    .checkboxContainer input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid var(--color-blue);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}

.checkboxContainer .caption {
    padding-top: 2px;
    color: #000;
}

.checkboxContainer:hover input ~ .checkmark {
}

.checkboxContainer input:disabled ~ .checkmark {
    border: 1px solid var(--color-grey);
    cursor: not-allowed;
}

.checkboxContainer input:disabled ~ .caption {
    color: var(--color-grey);
    cursor: not-allowed;
}

.checkboxContainer input:checked ~ .checkmark {
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkboxContainer input:checked ~ .checkmark:after {
    display: block;
}

.checkboxContainer .checkmark:after {
    left: 6px;
    top: 4px;
    width: 5px;
    height: 8px;
    border: solid var(--color-blue);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


.control.textarea {
    line-height: 20px;
    font-family: 'Montserrat';
}


@media (max-width: 640px) {
    .mobile-hidden {
        display: none;
    }

    .list .table .row .cell.mobile-hidden {
        display: none;
    }
}


.react-time-picker__wrapper {
    border: none;
}


.react-time-picker__inputGroup input:valid {
    border: none;
}

.react-time-picker__inputGroup input:invalid {
    border: none;
}


.react-time-picker__inputGroup__input {
    min-width: 35px;
}

    .react-time-picker__inputGroup__input:invalid {
        background: #fff;
    }